import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const dom = $(el);
    const button = dom.find('[aria-expanded]');

    const showMenu = () => {
        button.attr('aria-expanded', 'true');
    };

    const hideMenu = () => {
        button.attr('aria-expanded', 'false');
    };

    const onMouseEnter = () => {
        showMenu();
    };

    const onMouseLeave = e => {
        if (!el.contains(e.relatedTarget)) {
            hideMenu();
        }
    };

    const onFocusIn = e => {
        showMenu();
    };

    const onFocusOut = e => {
        if (!el.contains(e.relatedTarget)) {
            hideMenu();
        }
    };

    const init = () => {
        button.on('mouseenter', onMouseEnter);
        dom.on('mouseleave', onMouseLeave);
        dom.on('focusin', onFocusIn);
        dom.on('focusout', onFocusOut);
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
